<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length>0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <el-table :data="tableData" stripe height="calc(100% - 80px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="shareMoney" label="分红金额（元）" width="150" align="center">
                    </el-table-column>
                    <el-table-column label="普通会员" align="center">
                        <el-table-column prop="commonTotal" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="commonMoney" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="VIP1" align="center">
                        <el-table-column prop="vip1Total" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="vip1Money" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="VIP2" align="center">
                        <el-table-column prop="vip2Total" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="vip2money" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="operationUser.userName" label="操作人" align="center">
                    </el-table-column>
                    <el-table-column prop="operationUser.phone" label="操作账户" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="分红时间" align="center" width="155px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.addTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Distributor } from "../../../components/HospitalDomain/Distributor";
export default {
    name: "OwnStats",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            Keyword: "",
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            searchForm: {
                type: '0',
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            title: '统一分红记录统计',
            DataCount: 0,
            json_fields: {
                "分红金额": 'shareMoney',
                "普通会员人数": 'commonTotal',
                "普通会员共计金额":'commonMoney',
                "VIP1会员人数": 'vip1Total',
                "VIP1会员共计金额":'vip1Money',
                "VIP2会员人数": 'vip2Total',
                "VIP2会员共计金额":'vip2money',
                "分红时间": {
                    filed: 'addTime',
                    callback: (val) => {
                        var val = val.addTime
                        return this.$fliters.timefilters(val)
                    }
                },
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                var item = _this.searchForm;
                if (_this.searchForm.datepicker) {
                    item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                    item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
                } else {
                    item.startTime = "";
                    item.endTime = "";
                }
                _this.DistributorDomain.GetPublicShareRecord(item.keyWord, item.startTime, item.endTime, 1, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.GetPublicShareRecord(item.keyWord, item.startTime, item.endTime, _this.PageIndex,null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    if (!item.keyWord) {
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
/*content*/



.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}
</style>
